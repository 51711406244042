import React from 'react';
import {Row, Col, Container}  from 'react-bootstrap';

export default function Footer() {

return (
  <>
  <Row className="footer g-0">

    <Col>
      <Container>
      <h4>CDI.CZ, s.r.o.</h4>
      <p>Na Rovnosti 2246/1, 130 00 Praha 3-Žižkov, Czechia</p>
      <p><a href="mailto:inf@cdi.cz"> info@cdi.cz</a></p>
      <p><a href="tel:774255796">774 255 796</a></p>
      </Container>
    </Col>
  </Row>
  <Row className="black copyright g-0">
    <Col>
    <Container>
      <p>©2022 by Ready Shops.</p>
    </Container>
  </Col>
  </Row>
  </>
  )
}
