import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';



export default function Navigation() {
  return (
    <>
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid className="navigation">
      <Navbar.Brand href="#home"><img src={"./cdi_logo.png"} alt="" /></Navbar.Brand>
        <p className="under">
          <a href="#home">Ready Shops</a> <br />
           E-shopové řešení na míru
        </p>
      </Container>
    </Navbar>
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container fluid>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="">
          <Nav.Link href="#text-box">about us</Nav.Link>
        </Nav>
        <Nav className="justify-content-end">
            <Nav.Link href="mailto:inf@cdi.cz">info@cdi.cz</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </Container>
    </Navbar>
    </>

  )

}
