import React from 'react';
import {Container, Row, Col, Card, CardGroup, Carousel } from 'react-bootstrap';




export default function Content() {
  return (
    <Container fluid className="main-content">

        <Row className="banner">
          <Col className="g-0 img-con" xl={{ span: 12}}>
            <img className="img" alt="" src={"./banner_new.png"} />
              <Col className="opacity"><a href="#kontakt">spojte se s námi</a>
              </Col>
          </Col>


        </Row>

        {/*Text Box*/}
      <Row className="black text-box" id="text-box">
        <Col xl={{ span: 6, offset: 3}}>
          <img fluid src={"./readyshops_logo.png"} alt=""/>
          <p center="true">Ready Shops je e-shopovým řešením na míru společnosti CDI.CZ, která se zabývá tvorbou softwaru a informačních systémů na míru již více než 25 let. Systém je naprosto modifikovatelný a proto je skvělým řešením pro společnosti s netradičními požadavky.
          </p>
          <p>
            Společnost CDI.CZ navíc disponuje odborníky v oblasti výkonnostního marketingu, díky čemuž může svým klientům nabídnout celkové řešení chodu e-shopu včetně online reklamy, kterou nejenže nastaví, ale také kompletně financuje. Klient se tak nemusí soustředit na nic jiného, než provoz svého e-shopu.
          </p>
          <p>
To vše je navíc až do první objednávky zcela zdarma. Za všechny služby klient platí pouze předem stanovenou marži z uskutečněnýh objednávek.
          </p>
          <p>
I proto je Ready Shops systémem ideálním pro klienty, kteří sice mají skvělý produkt, ale nevědí, jak se prosadit na dnešním e-commerce trhu a nechtějí investovat přemrštěné částky do e-shopu na míru, marketingového týmu a samotných online kampaní, aniž by měli jistotu úspěchu.
          </p>
        </Col>
      </Row>


    {/*Marketing info*/}
      <Row className="light-blue marketing">
        <Col className="black" lg={{ span: 6}} sm={{ span: 12}} >
          <h4>S námi platíte až od  první objednávky</h4>
          <p>Vytvoření a nastavení Vašeho e-shopu, nastavení marketingových nástrojů a všechna další práce na e-shopu je zdarma. Platíte až provizi po první objednávce. Neplatíte ani za online marketingové kampaně. Až do první objednávky tedy opravdu nezaplatíte ani korunu.</p>
        </Col>
        <Col className="cart" lg={{ span: 6}} sm={{ span: 12}} >
          <img fluid src={"./cart.png"} alt="" />
        </Col>
      </Row>



    {/*Card*/}
      <Row className="center black">
        <h4>Co vše s námi získáte?</h4>
        <p>Přípravou e-shopu na míru naše spolupráce nekončí. Zajistíme za Vás také celkový online marketing, který zároveň platíme. Na Vás  zbyde už jen vyřizování objednávek. Za všechny naše služby platíte pouze předem domluvenou provizi z uskutečněných objednávek.</p>
      </Row>
      <Row className="black">
        <CardGroup>
          <Card className="dark-grey">
            <Card.Img variant="top" src="./card1.png" />
            <Card.Body>
              <Card.Title>Tvorba e-shopu na míru</Card.Title>
              <Card.Text>Náš e-shopový systém dokážeme přizpůsobit jakýmkoliv požadavkům klienta. Přejete si napojení na Váš skladový systém, export dat do účetnách systémů, či máte jakýkoliv jiný požadavek? Vše společně zvládneme. E-shop je od prvního dne spuštění 100% funkční.</Card.Text>
            </Card.Body>
          </Card>
          <Card className="dark-blue">
            <Card.Img variant="top" src="./card2.png" />
            <Card.Body>
              <Card.Title>Výkononostní online marketing</Card.Title>
              <Card.Text>Nechcete platit marketingový tým? Nechte to na nás. Postaráme se o PPC systémy Google Ads, Sklik a Facebook Ads. Napojíme a postaráme se o e-shop na zbožových srovnávačích. Neustále pracujeme také na zlepšování SEO.
Všechny marketingové kanály platíme my!</Card.Text>
            </Card.Body>
          </Card>
          <Card className="light-blue">
            <Card.Img variant="top" src="./card3.png" />
            <Card.Body>
              <Card.Title>Další rozvoj E-shopu</Card.Title>
              <Card.Text>Společně s našimi zákazníky neustále pracujeme na rozvoji e-shopů a systému Ready Shops. Rádi Vám vyhovíme s přidáním nových funkcí, optimalizací  systému pro Vaše potřeby, či expanzi Vašeho produktu do zahraničí.</Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
      </Row>
      <Row>



        <Carousel fade variant="dark">
          <Carousel.Item>
            <Row><Col><img
              className="d-block w-100"
              src="./payment.png"
              alt="first-slide"
              /></Col>
            <Col className="center">
              <h2> Platební Brány</h2>
              <p>K e-shopu dokážeme připojit všechny typy platebních bran využívaných v ČR včetně netradičních způsobů placení jako je odložená platba MALL PAY.</p>
            </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row><Col><img
              className="d-block w-100"
              src="./delivery.png"
              alt="second-slide"
              /></Col>
            <Col className="center">
              <h2>Zásilkové a dopravní služby</h2>
              <p>Váš e-shop rádi napojíme na jakoukoliv záslkovou službu využívanou v ČR včetně automatického exportu dat a tisku štítků pro evidenci balíků.</p>
            </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Row><Col><img
              className="d-block w-100"
              src="./warehouse.png"
              alt="third-slide"
              /></Col>
            <Col className="center">
              <h2>Skladové a účetní systémy</h2>
              <p>Ready Shops lze připojit na mnoho skladových a účetních systémů. Export a import dat vždy přuzpůsobíme Vašim požadavkům.</p>
            </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </Row>
    </Container>
  )

};
