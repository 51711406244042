
import Navigation from './Navigation.js';
import Content from './Content.js';
import ContactUs from './ContactForm.js';
import Footer from './Footer.js'
import { Helmet } from 'react-helmet';




//Import of Boostrap

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';



function App() {

  return (
<>
<Helmet>
      <title>ReadyShops | Vaše E-shopové řešení</title>
      <meta name='description' content='Ready Shops je e-shopovým řešením na míru společnosti CDI.CZ, která se zabývá tvorbou softwaru a informačních systémů na míru již více než 25 let.' />
    </Helmet>
  <Navigation />
  <Content />
  <ContactUs/>
  <Footer />
    </>
  );
}

export default App;
