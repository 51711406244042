import emailjs from "emailjs-com";
import React from 'react';

export default function Contact() {

    function sendEmail(e) {
        e.preventDefault();

    emailjs.sendForm('readyshops_submit', 'template_e0cbp4c', e.target, 'fsV062B-YRm7nIROW')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(
      <form autoComplete="on" onSubmit={sendEmail} id="kontakt">
        <div>
          <h4 className="white">Máte zájem o spolupráci, nebo se chcete na něco zeptat?</h4>
          <p className="bold white">Kontaktujte nás!</p>
          <input type='text'  placeholder='Jméno a příjmení' height="60" required name="name" />
          <input type='email'  placeholder='Email' required name="email" />
          <input type='tel'  placeholder='Telefon' required name="tel" />
          <textarea className="comment" type='text'  placeholder='Vzkaz' name="message"></textarea>
          <button type="submit">Kontaktujte nás</button>


        </div>
      </form>

    )
}
